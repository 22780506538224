import {
  RiseOutlined,
  PlusOutlined,
  BulbOutlined,
  TagsOutlined,
  GoldOutlined,
  FundOutlined,
  DollarOutlined,
  SlidersOutlined,
  MonitorOutlined,
  DatabaseOutlined,
  FontSizeOutlined,
  FieldTimeOutlined,
  DashboardOutlined,
  FileSearchOutlined,
  ShrinkOutlined,
  DotChartOutlined,
  BarChartOutlined,
  GroupOutlined,
} from '@ant-design/icons';

const dashBoardNavTree = [
  {
    key: 'zap',
    path: `/`,
    title: 'appTitle',
    icon: DashboardOutlined,
    breadcrumb: false,
    access: ['admin', 'user'],
    submenu: [
      {
        key: 'dashboard',
        path: `/`,
        title: 'dashboard',
        icon: DashboardOutlined,
        breadcrumb: false,
        access: ['admin', 'user'],
        submenu: [],
      },
      {
        key: 'product-catalog',
        path: `/product-catalog`,
        title: 'productCatalog',
        icon: TagsOutlined,
        breadcrumb: false,
        access: ['admin', 'user'],
        submenu: [
          {
            path: `/product-catalog`,
            title: 'productCatalog',
            icon: TagsOutlined,
            breadcrumb: false,
            access: ['admin', 'user'],
          },
          {
            key: 'sku-mappings',
            path: `/sku-mappings`,
            title: 'skuMappings',
            icon: ShrinkOutlined,
            breadcrumb: false,
            access: ['admin', 'user'],
          },
          {
            key: 'sales-mapping',
            path: `/sales-mapping`,
            title: 'salesMapping',
            icon: ShrinkOutlined,
            breadcrumb: false,
            access: ['admin', 'user'],
          },
        ],
      },
      {
        key: 'inventory',
        path: `/inventory`,
        title: 'inventory',
        icon: DatabaseOutlined,
        breadcrumb: false,
        access: ['admin', 'user'],
        submenu: [
          {
            key: 'forecast',
            path: `/forecast`,
            title: 'forecast',
            icon: DatabaseOutlined,
            breadcrumb: false,
            access: ['admin', 'user'],
          },
          {
            key: 'po',
            path: `/po`,
            title: 'po',
            icon: DatabaseOutlined,
            breadcrumb: false,
            access: ['admin', 'user'],
          },
          {
            key: 'inventory',
            path: `/inventory`,
            title: 'inventory',
            icon: DatabaseOutlined,
            breadcrumb: false,
            access: ['admin', 'user'],
          },
          {
            key: 'amz-stock-health',
            path: `/amz-stock-health`,
            title: 'amzStockHealth',
            icon: DatabaseOutlined,
            breadcrumb: false,
            access: ['admin', 'user'],
          },
        ],
      },
      {
        key: 'sales',
        path: `/sales`,
        title: 'sales',
        icon: DollarOutlined,
        breadcrumb: false,
        access: ['admin', 'user'],
        submenu: [
          {
            key: 'sales-dashboard',
            path: `/sales`,
            title: 'salesDashboard',
            icon: DollarOutlined,
            breadcrumb: false,
            access: ['admin', 'user'],
          },
          {
            key: 'sales-trends',
            path: `/sales-trends`,
            title: 'salesTrends',
            icon: DotChartOutlined,
            breadcrumb: false,
            access: ['admin', 'user'],
          },
          {
            key: 'sales-report',
            path: '/sales-report',
            title: 'salesReport',
            icon: BarChartOutlined,
            breadcrumb: false,
            access: ['admin', 'user'],
          },
        ],
      },
      {
        key: 'sales-planning',
        path: `/sales-planning`,
        title: 'salesPlanning',
        icon: DollarOutlined,
        breadcrumb: false,
        access: ['admin', 'user'],
        submenu: [],
      },
      {
        key: 'sales-v2',
        path: `/sales-v2`,
        title: 'salesV2',
        icon: DollarOutlined,
        breadcrumb: false,
        access: ['admin', 'user'],
        submenu: [],
      },
      {
        key: 'advertising',
        path: `/advertising`,
        title: 'advertising',
        icon: RiseOutlined,
        breadcrumb: false,
        access: ['admin'],
        submenu: [
          {
            key: 'advertising-add',
            path: `/advertising`,
            title: 'addCampaign',
            icon: PlusOutlined,
            breadcrumb: false,
            access: ['admin'],
            submenu: [],
          },
          {
            key: 'advertising-optimize',
            path: `/advertising/optimize`,
            title: 'optimizeCampaigns',
            icon: RiseOutlined,
            breadcrumb: false,
            access: ['admin'],
            submenu: [],
          },
          {
            key: 'advertising-rules',
            path: `/advertising/rules`,
            title: 'rules',
            icon: SlidersOutlined,
            breadcrumb: false,
            access: ['admin'],
            submenu: [],
          },
          {
            key: 'advertising-logs',
            path: `/advertising/logs`,
            title: 'logs',
            icon: BulbOutlined,
            breadcrumb: false,
            access: ['admin'],
            submenu: [],
          },
        ],
      },
      {
        key: 'reports',
        path: `/reports`,
        title: 'reporting',
        icon: FileSearchOutlined,
        breadcrumb: false,
        access: ['admin'],
        submenu: [
          {
            key: 'shipments',
            path: `/shipments`,
            title: 'shipments',
            icon: GoldOutlined,
            breadcrumb: false,
            access: ['admin', 'user'],
            submenu: [],
          },
          {
            key: 'cron-report',
            path: `/reports/cron`,
            title: 'cronLogs',
            icon: FieldTimeOutlined,
            breadcrumb: false,
            access: ['admin'],
            submenu: [],
          },
          {
            key: 'searchTerm-report',
            path: `/reports/searchTerm`,
            title: 'searchTermReport',
            icon: MonitorOutlined,
            breadcrumb: false,
            access: ['admin'],
            submenu: [],
          },
        ],
      },
      {
        key: 'keywords',
        path: `/keywords`,
        title: 'keywords',
        icon: FontSizeOutlined,
        breadcrumb: false,
        access: ['admin'],
        submenu: [
          {
            key: 'keyword-management',
            path: '/keyword-management',
            title: 'keywordManagement',
            icon: DashboardOutlined,
            access: ['admin'],
            submenu: [],
          },
          {
            key: 'ranking',
            path: `/ranking`,
            title: 'keywordRanking',
            icon: FundOutlined,
            breadcrumb: false,
            access: ['admin'],
            submenu: [],
          },
          {
            key: 'keyword-groups',
            path: `/keyword-groups`,
            title: 'keywordGroups',
            icon: GroupOutlined,
            breadcrumb: false,
            access: ['admin'],
            submenu: [],
          },
        ],
      },
      {
        key: 'market-research',
        path: `/market-research`,
        title: 'marketResearchDiscovery',
        icon: DatabaseOutlined,
        breadcrumb: false,
        access: ['admin', 'user'],
      },
      // {
      //   key: 'products',
      //   path: `/products`,
      //   title: 'Products',
      //   icon: BarcodeOutlined,
      //   breadcrumb: false,
      //   access: ['admin'],
      //   submenu: [],
      // },
      // {
      //   key: 'users',
      //   path: `/users`,
      //   title: 'Users',
      //   icon: UsergroupAddOutlined,
      //   breadcrumb: false,
      //   access: ['admin'],
      //   submenu: [],
      // },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
